<template>
  <div class="container">
    <div class="image_prev column align-items-center position-relative mb-5">
      <img class="w-100" :src="image" alt="" style="max-height: 480px;object-fit:contain;" />
      <p
        class="banner_text"
        >
        <!-- style="top: 200px; right: 100px; color: #fff; word-wrap: break-word" -->
        {{ details }}
      </p>
    </div>
    <div class="modal-footer gap-3 justify-content-center mb-4">
      <RouterLink
        :to="{
          path: `/single_banner_view/${id}`,
          query: {
            image: image,
            details: details,
          },
        }"
        class="main-btn md up"
        :disabled="disabled"
      >
        تعديل البانر
        <div class="spinner-border" role="status" v-if="disabled">
          <span class="visually-hidden">Loading...</span>
        </div>
      </RouterLink>
      <button
        type="button"
        class="main-btn md up red"
        :disabled="disabled"
        data-bs-target="#alertModal2"
        data-bs-toggle="modal"
      >
        حذف البانر
        <div class="spinner-border" role="status" v-if="disabled">
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </div>
    <div
      class="modal fade"
      id="alertModal2"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel2"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            type="button"
            class="close-model-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa-regular fa-circle-xmark"></i>
          </button>

          <div class="content-model-me">
            <div class="modal-header">
              <h2 class="section-title">هل تود بالفعل حذف البانر؟</h2>
            </div>

            <div class="modal-body">
              <img :src="logOutImg" alt="" class="done-img" />
            </div>

            <div class="modal-footer">
              <button
                class="main-btn md up"
                :disabled="disabled"
                type="button"
                @click.prevent="deleteBanner()"
              >
                نعم
                <div class="spinner-border" role="status" v-if="disabled">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
              <button
                class="main-btn red md up"
                type="submit"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                لا
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logOutImg: require("@/assets/imgs/alert.gif"),
    };
  },
};
</script>

<script setup>
import { ref, onMounted } from "vue";
import { RouterLink, useRoute, useRouter } from "vue-router";
import axios from "axios";
const route = useRoute(),
  router = useRouter(),
  id = route.params.id,
  disabled = ref(false),
  adPanner = ref(false),
  dialog = ref(false),
  image = ref(),
  details = ref(),
  deleteBanner = async () => {
    disabled.value = true;
    document.getElementById("alertModal2").style.display = "none";
    // document.querySelector(".modal-backdrop").style.display = "none";
    await axios
      .post(`delete-banner/${route.params.id}`, "", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.key == "success") {
          adPanner.value = false;
          dialog.value = true;
          document.querySelectorAll(".modal-backdrop").forEach((element) => {
            element.classList.remove("show");
            element.style.display = "none";
          });
          setTimeout(() => router.push("/bannerView"), 100);
        } else {
          this.$swal({
            icon: "error",
            title: res.data.msg,
            timer: 2000,
            showConfirmButton: false,
          });
        }
        disabled.value = false;
      });
  };
onMounted(() => {
  axios.get(`show-banner/${id}` , {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
    // console.log(res?.data?.data);
    image.value = res?.data?.data?.image;
    details.value = res.data.data.details;
  });
});
</script>

<style lang="scss" scoped>
  .banner_text{
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    font-size: clamp(16px , 4vw , 22px);
    color: #fff;
  }
</style>
